import React, { useCallback, useContext, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { getSingedUrlFile } from "../../../helpers/awsHelper";
import {
  CreateFilterItem,
  FilterItem,
  FilterList,
  GroupType,
  OperatorType,
  SubGroupFilterItem,
  targetUserInfo,
} from "../types/targetV2.types";
import { initialQueryContainerDataEmpty } from "../mocks/targetV2.mocks";
import _ from "lodash";
import {
  getFiltersData,
  postFilterValues,
  postVolumesCountHCPS,
  postTargetList,
  downloadTemplate,
} from "services/targetV2Service";
import { getDashboardItemDetails } from "services/dashboardService";
import {
  generateFirstGroupOfTargetList,
  generateSubGroupFilter,
} from "../utils/targetV2.utils";
import { getUpdateSavedTargetsData } from "services/savedTargetService";
import { useRecoilValue } from "recoil";
import { userDetailsState } from "recoil/atoms";

interface TargetV2ContextProps {
  onDragEnd: ({ source, destination, type }: DropResult) => null | undefined;
  filtersDropdownData: Array<FilterList>;
  setFiltersDropdownData: React.Dispatch<
    React.SetStateAction<Array<FilterList>>
  >;
  targetListData: Array<SubGroupFilterItem>;
  setTargetListData: React.Dispatch<
    React.SetStateAction<Array<SubGroupFilterItem>>
  >;
  countryList: Array<string>;
  setCountryList: React.Dispatch<React.SetStateAction<Array<string>>>;
  targetListPreviewText: string;
  setTargetListPreviewText: React.Dispatch<React.SetStateAction<string>>;
  targetListPreviewSignedURL: string;
  setTargetListPreviewSignedURL: React.Dispatch<React.SetStateAction<string>>;
  targetListName: string;
  setTargetListName: React.Dispatch<React.SetStateAction<string>>;
  targetListType: null | string;
  setTargetListType: React.Dispatch<React.SetStateAction<null | string>>;
  varianceTL: any;
  setVarianceTL: React.Dispatch<React.SetStateAction<any>>;  
  showCustomMsg: boolean;
  setShowCustomMsg: React.Dispatch<React.SetStateAction<boolean>>;  
  userInteraction: null | string;
  setUserInteraction: React.Dispatch<React.SetStateAction<null | string>>;   
  targetListCsvPreviewUrl: string;
  setTargetListCsvPreviewUrl: React.Dispatch<React.SetStateAction<string>>;
  targetListCsvProcessingUrl: string;
  setTargetListCsvProcessingUrl: React.Dispatch<React.SetStateAction<string>>;
  targetListId: number;
  setTargetListId: React.Dispatch<React.SetStateAction<string>>;
  isSearching: boolean;
  setIsSearching: React.Dispatch<React.SetStateAction<boolean>>;
  searchingFiltersResults: Array<FilterList>;
  setSearchingFiltersResults: React.Dispatch<
    React.SetStateAction<Array<FilterList>>
  >;
  handleGenerateTargetListPreviewText: (
    arr: SubGroupFilterItem,
    level: number
  ) => string[];
  handleDeleteFilterRow: (id: number, index: number) => void;
  handleUpdateTargetListData: (newState: SubGroupFilterItem[]) => void;
  handleUpdateFilterSelected: (
    id: number,
    indexOfFiltersItem: number,
    updateType: "Add" | "Remove",
    value: string,
    level: number,
    name?: string
  ) => void;
  handleUpdateSurveyNameFilterSelected: (
    id: number,
    indexOfFiltersItem: number,
    updateType: "Add" | "Remove",
    value: string,
    level: number,
    name?: string
  ) => void;  
  createNewMCCP: (
    arr: SubGroupFilterItem,
    value: string,
    parent: any
  ) => SubGroupFilterItem;
  
  createSurveyFilter: (
    arr: any,
    parentId: any,
    value: any,
    parent: any
  ) => any;
  handleChangeFiltersSelected: (
    arr: SubGroupFilterItem[],
    id: number,
    indexOfFiltersItem: number,
    updateType: "Add" | "Remove",
    value: string,
    level: number,
    name?: string
  ) => SubGroupFilterItem[];
  handleDeleteSubGroup: (
    arr: SubGroupFilterItem[],
    id: number,
    index: number
  ) => void;
  handleDeleteSubGroupFilterItem: (id: number | null, index: number) => void;
  groupExpanded: number;
  setGroupExpanded: React.Dispatch<React.SetStateAction<number>>;
  handleCreateNewGroup: () => void;
  handleReplicatePreviousGroup: (groupToReplicate: SubGroupFilterItem) => void;
  getFormattedURl: (payload: any) => void;
  handleUpdateSimpleFilterSelected: (
    id: number,
    indexOfFiltersItem: number,
    value: string,
    isReponsive: boolean
  ) => void;
  arrayOfOperators: OperatorType[];
  handleUpdateOperator: (id: number, operator: OperatorType) => void;
  handleUpdateOperatorBetweenGroups: (
    id: number,
    index: number,
    operator: OperatorType
  ) => void;
  handleUpdateDateRange: (
    id: number,
    indexOfFiltersItem: number,
    startDate: Date,
    endDate: Date,
    isResponsive: boolean,
    groupType?: string
  ) => void;
  isApplyingFilters: boolean;
  setIsApplyingFilters: React.Dispatch<React.SetStateAction<boolean>>;
  handleFindMandatoryMissing: (
    arr: SubGroupFilterItem[]
  ) => SubGroupFilterItem | undefined;
  isMissingMandatoryFields: boolean;
  setIsMissingMandatoryFields: React.Dispatch<React.SetStateAction<boolean>>;
  fetchDependenciesOfFilters: (values: SubGroupFilterItem) => void;
  fetchCountOfVolumes: (payload: SubGroupFilterItem, id: any) => void;
  saveTargetList: (filterItem: SubGroupFilterItem, id: any) => void;
  fetchFiltersListData: (id?: any) => void;
  fetchCustomTemplate: () => void;
  saveTargetonApplyFilters: (
    filterItem: SubGroupFilterItem,
    previewUrl: string,
    processingUrl: string
  ) => void;
  handleUpdateMultiFilterSelected: (
    id: number,
    indexOfFiltersItem: number,
    updateType: "Add" | "Remove",
    value: string,
    level: number,
    name?: string
  ) => void;
  handleUpdateOperatorBetweenFiltersSelected: (
    id: number,
    index: number,
    operator: OperatorType
  ) => void;
}

const TargetV2Context = React.createContext<TargetV2ContextProps>(
  {} as TargetV2ContextProps
);
TargetV2Context.displayName = "TargetV2ContextProvider";

interface TargetV2ProviderProps {
  children: (props: TargetV2ContextProps) => React.ReactElement;
}

const TargetV2Provider: React.FC<TargetV2ProviderProps> = ({ children }) => {
  const [filtersDropdownData, setFiltersDropdownData] = useState<
    Array<FilterList>
  >([]); // list of filters available (left bar)

  const [targetListData, setTargetListData] = useState<
    Array<SubGroupFilterItem>
  >([...initialQueryContainerDataEmpty]); // data inside dropzone which build the query

  const [targetListPreviewText, setTargetListPreviewText] =
    useState<string>(""); // used to create the summary
  const [targetListName, setTargetListName] = useState<string>("");
  const [targetListType, setTargetListType] = useState<null | string>(null);
  const [varianceTL, setVarianceTL] = useState<null | string>(null);
  const [userInteraction, setUserInteraction] = useState<null | string>(null);
  const [showCustomMsg, setShowCustomMsg] = useState<boolean>(true);  
  const [targetListPreviewSignedURL, setTargetListPreviewSignedURL] =
    useState<string>(""); // used to store pre-signed URL
  const [targetListId, setTargetListId] = useState<any>(); // used to store target id
  const [targetListCsvPreviewUrl, setTargetListCsvPreviewUrl] = useState<any>(); // used to store backend csv url
  const [targetListCsvProcessingUrl, setTargetListCsvProcessingUrl] =
    useState<any>(); // used to store backend csv url with all details
  const [countryList, setCountryList] = useState<Array<string>>([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchingFiltersResults, setSearchingFiltersResults] = useState<
    Array<FilterList>
  >([]);

  const [groupExpanded, setGroupExpanded] = useState<number>(
    targetListData[0] &&
      targetListData[0].subgGroupFilterItemsChildren &&
      targetListData[0].subgGroupFilterItemsChildren[0]?.subGroupId
  );
  const userDetails = useRecoilValue(userDetailsState);

  const [isApplyingFilters, setIsApplyingFilters] = useState<boolean>(false);

  const [isMissingMandatoryFields, setIsMissingMandatoryFields] =
    useState<boolean>(false);

  const arrayOfOperators = Object.values(OperatorType);

  const handleDeleteFilterItem = useCallback(
    (arr: SubGroupFilterItem[], id: number, index: number) => {
      arr.map((e) => {
        if (e.subGroupId === id) {
          e.filterItemsChildren.splice(index, 1);
        } else {
          handleDeleteFilterItem(e.subgGroupFilterItemsChildren, id, index);
        }
        return e;
      });
    },
    []
  );

  const handleDeleteGroup = useCallback(
    (array: SubGroupFilterItem[], id: number) => {
      const resultArray = array
        .map((val: SubGroupFilterItem, index: number) => {
          if (val.subGroupId === id) {
            return { ...val, ...{ subGroupId: 1 } };
          } else {
            const sub: any = handleDeleteGroup(
              val.subgGroupFilterItemsChildren,
              id
            );
            return { ...val, ...{ subgGroupFilterItemsChildren: sub } };
          }
        })
        .filter((val) => val.subGroupId !== 1);
      if (resultArray) {
        return resultArray;
      }
    },
    []
  );

  const findFilterItemById = useCallback(
    (
      array: SubGroupFilterItem[],
      id: number
    ): SubGroupFilterItem | undefined => {
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        if (element.subGroupId === id) {
          return element;
        } else {
          if (element.subgGroupFilterItemsChildren) {
            const found = findFilterItemById(
              element.subgGroupFilterItemsChildren,
              id
            );

            if (found) {
              return found;
            }
          }
        }
      }
    },
    []
  );

  const handleAddFilterItem = useCallback(
    (
      arr: SubGroupFilterItem[],
      id: number,
      item: FilterItem,
      index: number
    ) => {
      arr.map((e) => {
        if (e.subGroupId === id) {
          e.filterItemsChildren.splice(index, 0, item);
        } else {
          handleAddFilterItem(e.subgGroupFilterItemsChildren, id, item, index);
        }
        return e;
      });
    },
    []
  );

  const handleAddFilterItemGroup = useCallback(
    (
      arr: SubGroupFilterItem[],
      id: number,
      groupFilter: SubGroupFilterItem,
      index: number
    ) => {
      arr.map((e) => {
        if (e.subGroupId === id) {
          e.subgGroupFilterItemsChildren.splice(index, 0, groupFilter);
        } else {
          handleAddFilterItemGroup(
            e.subgGroupFilterItemsChildren,
            id,
            groupFilter,
            index
          );
        }
        return e;
      });
    },
    []
  );

  const handleReorderFilterItem = useCallback(
    (
      arr: SubGroupFilterItem[],
      id: number,
      item: FilterItem,
      endIndex: number,
      startIndex: number
    ) => {
      const newArr = arr.map((e) => {
        if (e.subGroupId === id) {
          const deleted = e.filterItemsChildren.splice(startIndex, 1);
          e.filterItemsChildren.splice(endIndex, 0, deleted[0]);
          return e;
        } else {
          handleReorderFilterItem(
            e.subgGroupFilterItemsChildren,
            id,
            item,
            endIndex,
            startIndex
          );
          return e;
        }
      });
      return newArr;
    },
    []
  );

  const handleUpdateTargetListData = useCallback(
    (newState: SubGroupFilterItem[]) => {
      setTargetListData([...newState]);
    },
    [setTargetListData]
  );

  const saveTargetonApplyFilters = useCallback(
    (filterItem, previewUrl, processingUrl) => {
      if (
        targetListId &&
        targetListName &&
        previewUrl &&
        processingUrl &&
        filterItem
      ) {
        const data = {
          targetId: targetListId,
          campaignName: targetListName,
          targetType: "campaign_target_list",
          targetData: filterItem,
          previewUrl: previewUrl,
          processingUrl: processingUrl,
        };
        getUpdateSavedTargetsData(data)?.then((response: any) => {
          if (response) {
            console.log("saved success", response);
          }
        });
      }
    },
    [targetListId, targetListName]
  );
  const getFormattedURl = useCallback(
    (payload: any) => {
      getSingedUrlFile(payload).then((res) => {
        if (res) {
          console.log(res);
          if (typeof res == "string" && res.includes("https://")) {
            setTargetListPreviewSignedURL(res);
          }
        }
      });
    },
    [setTargetListPreviewSignedURL]
  );
  const fetchCustomTemplate = useCallback(() => {
    downloadTemplate().then((res) => {
      if (res) {
        if (res.data?.template) {
          getSingedUrlFile(res.data.template).then((resp) => {
            if (resp) {
              if (typeof resp == "string" && resp.includes("https://")) {
                var fileName = "SampleTemplate.csv";
                var link = document.createElement("a");
                link.setAttribute("href", resp);
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }
          });
        }
      }
    });
  }, []);

  const fetchFiltersListData = useCallback(
    (id) => {
      let savedData: any[];
      let savedpreviewURI: any;
      let savedprocessingURI: any;
      let savedTargetID: any;
      let savedTargetName: any;
      let savedTargetType: any;
      let savedVarianceTL: any;
      let savedUserInteraction: any;

      if (id) {
        getDashboardItemDetails(id).then((res) => {
          console.log("async filter data id", res);
          if (
            res &&
            res.data &&
            res.data.data &&
            res.data.data.requestV2 &&
            res.data.data.requestV2.targetData
          ) {
            const formattedData = [res.data.data.requestV2.targetData];
            savedData = [...formattedData];
          }
          if (
            res &&
            res.data &&
            res.data.data &&
            res.data.data.requestV2 &&
            res.data.data.requestV2.campaignName
          ) {
            savedTargetName = res.data.data.requestV2.campaignName;
          }
          if (
            res &&
            res.data &&
            res.data.data &&
            res.data.data.requestV2 &&
            res.data.data.requestV2.targetType
          ) {
            savedTargetType = res.data.data.requestV2.targetType;
          }
          if (
            res &&
            res.data &&
            res.data.data &&
            res.data.data.requestV2 &&
            res.data.data.requestV2.previewUrl
          ) {
            savedpreviewURI = res.data.data.requestV2.previewUrl;
          }
          if (
            res &&
            res.data &&
            res.data.data &&
            res.data.data.requestV2 &&
            res.data.data.requestV2.processingUrl
          ) {
            savedprocessingURI = res.data.data.requestV2.processingUrl;
          }
          if (
            res &&
            res.data &&
            res.data.data &&
            res.data.data.requestV2 &&
            res.data.data.requestV2.targetId
          ) {
            savedTargetID = res.data.data.requestV2.targetId;
          }
          if (
            res &&
            res.data &&
            res.data.data &&
            res.data.data.requestV2 &&
            res.data.data.requestV2.varianceTL
          ) {
            savedVarianceTL = res.data.data.requestV2.varianceTL;
          }
          if (
            res &&
            res.data &&
            res.data.data &&
            res.data.data.requestV2 &&
            res.data.data.requestV2.userInteraction
          ) {
            savedUserInteraction= res.data.data.requestV2.userInteraction;
          }
          let payload: targetUserInfo = {
            emailId: userDetails.email,
          };

          getFiltersData(payload).then((res) => {
            console.log("async filter data", res);
            if (res?.data?.filter_category_list) {
              setFiltersDropdownData(res.data.filter_category_list);
            } else {
              setFiltersDropdownData([]);
            }
            if (res?.data?.country_list) {
              setCountryList(res.data.country_list);
              const initialResponse = [
                {
                  id: 12345,
                  subGroupId: 12345,
                  name: "Start selecting your filters",
                  operator: OperatorType.AND,
                  parentId: null,
                  groupType: GroupType.CLASSIC,
                  filterItemsChildren: [
                    {
                      id: 123,
                      filterId: 123,
                      name: "Country",
                      filters: res.data.country_list,
                      filtersSelected: [],
                      isMandatory: true,
                      isResponsive: true,
                    },
                  ],
                  subgGroupFilterItemsChildren: [],
                },
              ];
              if (savedData) {
                setTargetListData(savedData);
                setIsApplyingFilters(true);
                setIsMissingMandatoryFields(false);
                if (savedpreviewURI) {
                  getFormattedURl(savedpreviewURI);
                  setTargetListCsvPreviewUrl(savedpreviewURI);
                }
                if (savedprocessingURI) {
                  setTargetListCsvProcessingUrl(savedprocessingURI);
                }
                if (savedTargetID) {
                  setTargetListId(savedTargetID);
                }
                if (savedTargetName) {
                  setTargetListName(savedTargetName);
                }
                if (savedTargetType) {
                  setTargetListType(savedTargetType);
                }
                if (savedVarianceTL) {
                  setVarianceTL(savedVarianceTL);
                }
                if (savedUserInteraction) {
                  setUserInteraction(savedUserInteraction);
                }
              } else {
                setTargetListData(initialResponse);
                setIsApplyingFilters(false);
                setTargetListPreviewSignedURL("");
                setTargetListName("");
                setTargetListType(null);
                setVarianceTL(null);
                setUserInteraction(null);
                setTargetListCsvPreviewUrl("");
                setTargetListCsvProcessingUrl("");
                setTargetListId(null);
              }
              // setTargetListData(initialResponse);
            } else {
              setTargetListData([]);
              setIsApplyingFilters(false);
              setTargetListPreviewSignedURL("");
              setTargetListName("");
              setTargetListType(null);
              setVarianceTL(null);
              setUserInteraction(null);
              setTargetListCsvPreviewUrl("");
              setTargetListCsvProcessingUrl("");
              setTargetListId(null);
            }
          });
        });
      } else {
        let payloadData: targetUserInfo = {
          emailId: userDetails.email,
        };
        getFiltersData(payloadData).then((res) => {
          console.log("async filter data", res);
          if (res?.data?.filter_category_list) {
            setFiltersDropdownData(res.data.filter_category_list);
          } else {
            setFiltersDropdownData([]);
          }
          if (res?.data?.country_list) {
            setCountryList(res.data.country_list);
            const initialResponse = [
              {
                id: 12345,
                subGroupId: 12345,
                name: "Start selecting your filters",
                operator: OperatorType.AND,
                parentId: null,
                groupType: GroupType.CLASSIC,
                filterItemsChildren: [
                  {
                    id: 123,
                    filterId: 123,
                    name: "Country",
                    filters: res.data.country_list,
                    filtersSelected: [],
                    isMandatory: true,
                    isResponsive: true,
                  },
                ],
                subgGroupFilterItemsChildren: [],
              },
            ];
            setTargetListData(initialResponse);
          } else {
            setTargetListData([]);
          }
          setIsApplyingFilters(false);
          setTargetListPreviewSignedURL("");
          setTargetListName("");
          setTargetListType(null);
          setVarianceTL(null);
          setUserInteraction(null);
          setTargetListCsvPreviewUrl("");
          setTargetListCsvProcessingUrl("");
          setTargetListId(null);
        });
      }
    },
    [getFormattedURl]
  );

  const removeFilterValues = useCallback(
    (subgGroupFilterItemsChildren: any) => {
      const filtersSelected: any = [];
      let filtersSelectedSub: any[] = [];
      subgGroupFilterItemsChildren.forEach((subChild: any) => {
        let res = {};
        const a = subChild.filterItemsChildren.map((child: any) => {
          if (child.id) {
            res = { id: child.id, filters: child.filters };
            child.filters = [];
          }
          return res;
        });
        filtersSelected.push(...a);
        if (subChild.subgGroupFilterItemsChildren) {
          filtersSelectedSub = removeFilterValues(
            subChild.subgGroupFilterItemsChildren
          );
        }
      });
      return [...filtersSelected, ...filtersSelectedSub];
    },
    []
  );

  const resetFilterValues = useCallback(
    (arr: SubGroupFilterItem[], filterSelected: any) => {
      arr.forEach((subChild: any) => {
        const a = subChild.filterItemsChildren.map((child: any) => {
          if (child.id) {
            let filteredArray = _.filter(filterSelected, { id: child.id });
            if (filteredArray.length > 0) {
              // console.log("filteredArray", filteredArray[0].filters);
              child.filters = filteredArray[0].filters;
            }
            // child.filters =[];
          }
          // return res;
        });

        if (subChild.subgGroupFilterItemsChildren) {
          resetFilterValues(
            subChild.subgGroupFilterItemsChildren,
            filterSelected
          );
        }
        return subChild;
      });
    },
    []
  );

  const fetchDependenciesOfFilters = useCallback(
    (payload: SubGroupFilterItem) => {
      console.log(
        "create payload dependencied api",
        payload.subgGroupFilterItemsChildren
      );
      const filtersSelectedInfo = removeFilterValues(
        payload.subgGroupFilterItemsChildren
      );
      console.log("filtersSelected dependencied", filtersSelectedInfo);

      postFilterValues(payload).then((res) => {
        if (res?.data) {

          // console.log("finalRes dependencied", res.data);
         handleUpdateTargetListData([res.data]);
          
        }
      });
    },
    [handleUpdateTargetListData, removeFilterValues]
  );

  const saveTargetList = useCallback(
    (filterItem: SubGroupFilterItem, id: any) => {
      if (
        filterItem &&
        filterItem.operatorsBetweenGroups &&
        filterItem.operatorsBetweenGroups.length === 0
      ) {
        const grpLength: number =
          filterItem.subgGroupFilterItemsChildren &&
          filterItem.subgGroupFilterItemsChildren.length > 0
            ? filterItem.subgGroupFilterItemsChildren.length - 1
            : 0;
        if (grpLength && grpLength !== 0) {
          let operatorArr = Array.from(
            {
              length: grpLength,
            },
            (value) => (value = OperatorType.AND)
          );

          filterItem = {
            ...filterItem,
            operatorsBetweenGroups: operatorArr,
          };
        }
      }
      let payload: CreateFilterItem = {
        filterValues: filterItem,
        targetId: id,
      };
      const filtersSelectedInfo = removeFilterValues(
        payload.filterValues.subgGroupFilterItemsChildren
      );

      postTargetList(payload).then((res) => {
        if (res?.data) {
          if (
            res &&
            res.data &&
            res.data.data &&
            res.data.data.uri &&
            res.data.data.preview_uri &&
            res.data.data.target_id
          ) {
            const finalRes = resetFilterValues(
              payload.filterValues.subgGroupFilterItemsChildren,
              filtersSelectedInfo
            );
            console.log("finalRes Create api", finalRes);

            getFormattedURl(res.data.data.preview_uri);
            setTargetListId(res.data.data.target_id);
            setTargetListCsvPreviewUrl(res.data.data.preview_uri);
            setTargetListCsvProcessingUrl(res.data.data.uri);
            if (payload.targetId) {
              saveTargetonApplyFilters(
                filterItem,
                res.data.data.preview_uri,
                res.data.data.uri
              ); // invoke only on edit option
            }
          }
        }
      });
    },
    [
      getFormattedURl,
      setTargetListId,
      setTargetListCsvPreviewUrl,
      setTargetListCsvProcessingUrl,
      saveTargetonApplyFilters,
      removeFilterValues,
      //targetListData,
    ]
  );

  const fetchCountOfVolumes = useCallback(
    (payload: SubGroupFilterItem, id: any) => {
      if (
        payload &&
        payload.operatorsBetweenGroups &&
        payload.operatorsBetweenGroups.length === 0
      ) {
        const grpLength: number =
          payload.subgGroupFilterItemsChildren &&
          payload.subgGroupFilterItemsChildren.length > 0
            ? payload.subgGroupFilterItemsChildren.length - 1
            : 0;
        if (grpLength && grpLength !== 0) {
          let operatorArr = Array.from(
            {
              length: grpLength,
            },
            (value) => (value = OperatorType.AND)
          );

          payload = {
            ...payload,
            operatorsBetweenGroups: operatorArr,
          };
        }
      }
      console.log("payload", payload);

      // const copyOfState: SubGroupFilterItem[] = _.cloneDeep(payload);

      const filtersSelected = removeFilterValues(
        payload.subgGroupFilterItemsChildren
      );
      postVolumesCountHCPS(payload).then((res) => {
        if (res?.data) {
          const finalRes = resetFilterValues([res.data], filtersSelected);
          console.log("finalRes", finalRes);

          const resData = [res.data];
          handleUpdateTargetListData([res.data]);
          setIsApplyingFilters(true);
          setIsMissingMandatoryFields(false);
          saveTargetList(resData[0], id);
        }
      });
    },
    [
      handleUpdateTargetListData,
      setIsApplyingFilters,
      setIsMissingMandatoryFields,
      saveTargetList,
      removeFilterValues,
      resetFilterValues,
    ]
  );

  const createNewMCCP = useCallback(
    (arr: SubGroupFilterItem, value: string, parent: any) => {
      const filterArray = [...arr.filterItemsChildren];
      const subGroupArray = [...arr.subgGroupFilterItemsChildren];
      let dummyArray = [];    


      for (let y = 0; y < subGroupArray.length; y++) {
        if (
          subGroupArray[y].filterItemsChildren &&
          subGroupArray[y].filterItemsChildren.length > 0
        ) {
          filterArray.push(subGroupArray[y].filterItemsChildren[0]);
        }
      }

      for (let k = 0; k < filterArray.length; k++) {
        const newFilterId = filterArray[k].filterId + 100;
        let val: string[];
        if (filterArray[k].name === "Date Cycle") {
          val = [value];
        } else {
          val = [];
        }
        const newArray = {
          ...filterArray[k],
          filterId: newFilterId,
          filtersSelected: val,
        };
        dummyArray.push(newArray);
      }
      dummyArray.sort((a, b) => a.id - b.id);
      const mccpItem = {
        ...arr,
        filterItemsChildren: dummyArray,
        subgGroupFilterItemsChildren: [],
        subGroupId: parent + 100,
        parentId: parent,
      };
      return mccpItem;
    },
    []
  );

  const createSurveyFilter = useCallback(
    (parentArr: any, parentId: any, value: any, currentArr: any) => {

      const currentArrCpy = [...currentArr];
      let surveyNameArray: any = [];
      for (let k = 0; k < currentArrCpy.length; k++) {
        const newFilterId = currentArrCpy[k].filterId ;

        let val: string[];
        if (currentArrCpy[k].name === "Survey name") {
          val = [value];
        } else {
          val = [];
        }
        const newArray = {
          ...currentArrCpy[k],
          countHCPS: null,
          filterId: newFilterId,
          filtersSelected: val,
        };
        surveyNameArray.push(newArray);
      }   

      return surveyNameArray[0];
    },
    []
  );
  const handleChangeFiltersSelected = useCallback(
    (
      arr: SubGroupFilterItem[],
      id: number,
      indexOfFiltersItem: number,
      updateType: "Add" | "Remove",
      value: string,
      level: number,
      name?: string
    ) => {     


      return arr.map((e, i) => {

        if (e.subGroupId === id) {
          let start;
          let end;
          if (updateType === "Add") {
            start = e.filterItemsChildren[indexOfFiltersItem].filters;
            end = e.filterItemsChildren[indexOfFiltersItem].filtersSelected;
          } else if (updateType === "Remove") {
            start = e.filterItemsChildren[indexOfFiltersItem].filtersSelected;
            end = e.filterItemsChildren[indexOfFiltersItem].filters;
          }

          if (start && end) {
            if (updateType === "Add") {
              const NameList = e.filterItemsChildren.filter(
                (item) =>
                  e.filterItemsChildren[indexOfFiltersItem].name === item.name
              );

              if (end.length < 1) {
                /* Add filterSelected*/
                end.push(value);
              } else if (
                end.length === 1 &&
                NameList &&
                NameList.length === 1
              ) {
                /* If there is one filterSelected , create a subgroup */
                /* Create subgroup */
                const firstFilters = _.cloneDeep(start);

                if (name === "Survey name") {
                
                 
                  const currenLooptItem = [ ...e.filterItemsChildren ];
                  const currentParentArray = [...arr];
               
                  const surveyGrpItem =createSurveyFilter(currentParentArray,e.parentId, value,currenLooptItem);
                  const generateUniqueId = Date.now();
                  const res: any ={              
                    id : generateUniqueId,
                    subGroupId :generateUniqueId,
                    name : "Survey Questions",
                    operator : OperatorType.AND,
                    groupType : GroupType.SURVEY_QUESTIONS,
                    filterItemsChildren : [surveyGrpItem],
                    subgGroupFilterItemsChildren : [],
                    isMCCPGroup :  false,
                    parentId: e.parentId
                  }
                 
                  arr.push({...res});                  
                 
                }else if (name === "Date Cycle") {

                  const currentItem: SubGroupFilterItem = { ...e };
                  const ParentArray = [...arr];
                  
                  const mccpArray = ParentArray.filter(
                    (item) => item.name === "MCCP"
                  );
                  let isMCCPArray = false;
                  if (
                    ParentArray.length &&
                    mccpArray &&
                    mccpArray.length &&
                    ParentArray.length === mccpArray.length
                  ) {
                    isMCCPArray = true;
                  }
                  if (isMCCPArray && ParentArray.length > 1) {
                    const MCCPItem = createNewMCCP(
                      currentItem,
                      value,
                      currentItem.parentId
                    );

                    arr.push(MCCPItem);
                  } else {
                    const generateUniqueId = Date.now();
                    const firstSubGpItem = { ...e, parentId: generateUniqueId };
                    const secondSubGpItem = createNewMCCP(
                      e,
                      value,
                      generateUniqueId
                    );

                    e.id = generateUniqueId;
                    e.subGroupId = generateUniqueId;
                    e.name = "Subgroup";
                    e.operator = OperatorType.AND;
                    e.groupType = GroupType.CLASSIC;
                    e.filterItemsChildren = [];
                    e.subgGroupFilterItemsChildren = [
                      firstSubGpItem,
                      secondSubGpItem,
                    ];
                    e.isMCCPGroup = true;
                  }
                } else {

                  // Delete filter in group
                  const filterToAdd = e.filterItemsChildren.splice(
                    indexOfFiltersItem,
                    1
                  );
                  const firstFilterItem: FilterItem = {
                    ...filterToAdd[0],
                    filters: firstFilters,
                  };

                  const secondFilterItem: FilterItem = {
                    ...filterToAdd[0],
                    filtersSelected: [value],
                    filters: firstFilters,
                    filterId: filterToAdd[0].filterId + Date.now(),
                  };

                  const generateUniqueId = Date.now();

                  e.subgGroupFilterItemsChildren = [
                    ...e.subgGroupFilterItemsChildren,
                    {
                      id: generateUniqueId,
                      subGroupId: generateUniqueId,
                      name: "Subgroup",
                      operator: OperatorType.AND,
                      parentId: e.id,
                      filterItemsChildren: [firstFilterItem, secondFilterItem],
                      subgGroupFilterItemsChildren: [],
                      groupType: GroupType.CLASSIC,
                    },
                  ];
                }
              } else if (end.length === 1) {
                /* No sub group */
                const generateUniqueId = Date.now();
                const exp = {
                  ...e.filterItemsChildren[0],
                  filterId: generateUniqueId,
                  filtersSelected: [value],
                };
                e.filterItemsChildren = [...e.filterItemsChildren, exp];
              }
            } else if (updateType === "Remove") {
              const indexOfDeletedItem = start.indexOf(value);
              start.splice(indexOfDeletedItem, 1);
            }
          }
        } else {
          if (name) {
            handleChangeFiltersSelected(
              e.subgGroupFilterItemsChildren,
              id,
              indexOfFiltersItem,
              updateType,
              value,
              level,
              name
            );
          } else {

            handleChangeFiltersSelected(
              e.subgGroupFilterItemsChildren,
              id,
              indexOfFiltersItem,
              updateType,
              value,
              level
            );
          }
        }

        return e;
      });
    },
    [createNewMCCP, createSurveyFilter]
  );

  const handleChangeMultiFiltersSelected = useCallback(
    (
      arr: SubGroupFilterItem[],
      id: number,
      indexOfFiltersItem: number,
      updateType: "Add" | "Remove",
      value: string
    ) => {
      return arr.map((e, i) => {
        if (e.subGroupId === id) {
          let start;
          let end;
          if (updateType === "Add") {
            start = e.filterItemsChildren[indexOfFiltersItem].filters;
            end = e.filterItemsChildren[indexOfFiltersItem].filtersSelected;
            end.push(value);
          } else if (updateType === "Remove") {
            start = e.filterItemsChildren[indexOfFiltersItem].filtersSelected;
            end = e.filterItemsChildren[indexOfFiltersItem].filters;
            const indexOfDeletedItem = start.indexOf(value);
            start.splice(indexOfDeletedItem, 1);
          }
        } else {
          handleChangeMultiFiltersSelected(
            e.subgGroupFilterItemsChildren,
            id,
            indexOfFiltersItem,
            updateType,
            value
          );
        }
        return e;
      });
    },
    []
  );
  // const handleChangeOperatorBetweenFiltersSelected = useCallback(
  //   (
  //     arr: SubGroupFilterItem[],
  //     id: number,
  //     index: number,
  //     operator: OperatorType
  //   ) => {
  //     return arr.map((e, i) => {
  //       if (e.subGroupId === id) {
  //         e.filterItemsChildren[index].operatorBetweenFiltersSelected =
  //           operator;
  //       } else {
  //         handleChangeOperatorBetweenFiltersSelected(
  //           e.subgGroupFilterItemsChildren,
  //           id,
  //           index,
  //           operator
  //         );
  //       }
  //       return e;
  //     });
  //   },
  //   []
  // );
  const handleChangeOperatorBetweenFiltersSelected = useCallback(
    (
      arr: SubGroupFilterItem[],
      id: number,
      index: number,
      operator: OperatorType
    ) => {
      return arr.map((e, i) => {
        if (e.subGroupId === id) {
        e.operator=operator;
      
        } else {
          handleChangeOperatorBetweenFiltersSelected(
            e.subgGroupFilterItemsChildren,
            id,
            index,
            operator
          );
        }
        return e;
      });
    },
    []
  );

  const handleUpdateOperatorBetweenFiltersSelected = useCallback(
    (id: number, index: number, operator: OperatorType) => {
      const copyOfState: SubGroupFilterItem[] = _.cloneDeep(targetListData);

      const result = handleChangeOperatorBetweenFiltersSelected(
        copyOfState,
        id,
        index,
        operator
      );
      handleUpdateTargetListData(result);
    },
    [
      handleChangeOperatorBetweenFiltersSelected,
      targetListData,
      handleUpdateTargetListData,
    ]
  );

  const handleCreateNewGroup = useCallback(() => {
    const newState = [...targetListData];
    if (targetListData[0].subgGroupFilterItemsChildren.length < 6) {
      const copyOfState: SubGroupFilterItem[] = _.cloneDeep(targetListData);

      const generateUniqueId = Date.now();

      if (copyOfState[0].operatorsBetweenGroups) {
        copyOfState[0].operatorsBetweenGroups = [
          ...copyOfState[0].operatorsBetweenGroups,
        ];
      } else {
        copyOfState[0].operatorsBetweenGroups = [];
      }

      const groupName = `Group`;

      copyOfState[0].subgGroupFilterItemsChildren.push({
        id: generateUniqueId,
        subGroupId: generateUniqueId,
        name: groupName,
        operator: OperatorType.AND,
        parentId: newState[0].id,
        filterItemsChildren: [],
        subgGroupFilterItemsChildren: [],
        groupType: GroupType.CLASSIC,
      });
      handleUpdateTargetListData(copyOfState);
      setGroupExpanded(generateUniqueId);
    }
  }, [targetListData, handleUpdateTargetListData]);

  const handleChangeEveryIdOfGroupToReplicate = useCallback(
    (arr: SubGroupFilterItem, parentId: number) => {
      const newIdOfSubGroup = arr.subGroupId + Date.now();
      arr.id = newIdOfSubGroup;
      arr.subGroupId = newIdOfSubGroup;
      arr.parentId = parentId;
      arr.filterItemsChildren.forEach((e, index) => {
        e.filterId = e.filterId + Date.now() + index;
        // e.id = e.id + Date.now() + index;
      });

      if (arr.subgGroupFilterItemsChildren.length > 0) {
        arr.subgGroupFilterItemsChildren.forEach((e) => {
          handleChangeEveryIdOfGroupToReplicate(e, newIdOfSubGroup);
        });
      }
    },
    []
  );

  const handleReplicatePreviousGroup = useCallback(
    (groupToReplicate: SubGroupFilterItem) => {
      const newState = [...targetListData];
      if (newState[0].subgGroupFilterItemsChildren.length < 6) {
        const copyOfState: SubGroupFilterItem[] = _.cloneDeep(targetListData);

        const groupName = `Group`;

        handleChangeEveryIdOfGroupToReplicate(
          groupToReplicate,
          copyOfState[0].subGroupId
        );

        const newGroup: SubGroupFilterItem = {
          ...groupToReplicate,
          name: groupName,
        };

        if (copyOfState[0].operatorsBetweenGroups) {
          copyOfState[0].operatorsBetweenGroups = [
            ...copyOfState[0].operatorsBetweenGroups,
          ];
        } else {
          copyOfState[0].operatorsBetweenGroups = [];
        }

        copyOfState[0].subgGroupFilterItemsChildren.push(newGroup);
        setGroupExpanded(newGroup.subGroupId);
        handleUpdateTargetListData(copyOfState);
        setIsMissingMandatoryFields(false);
      }
    },
    [
      targetListData,
      handleUpdateTargetListData,
      handleChangeEveryIdOfGroupToReplicate,
    ]
  );

  const handleUpdateFilterSelected = useCallback(
    (
      id: number,
      indexOfFiltersItem: number,
      updateType: "Add" | "Remove",
      value: string,
      level: number,
      name?: string
    ) => {
      const copyOfState: SubGroupFilterItem[] = _.cloneDeep(targetListData);
      let result;
      if (name) {
        result = handleChangeFiltersSelected(
          copyOfState,
          id,
          indexOfFiltersItem,
          updateType,
          value,
          level,
          name
        );
      } else {
        result = handleChangeFiltersSelected(
          copyOfState,
          id,
          indexOfFiltersItem,
          updateType,
          value,
          level
        );
      }

      handleUpdateTargetListData(result);
    },
    [handleChangeFiltersSelected, targetListData, handleUpdateTargetListData]
  );

  const handleUpdateMultiFilterSelected = useCallback(
    (     
      id: number,
      indexOfFiltersItem: number,
      updateType: "Add" | "Remove",
      value: string,
      level: number,
      name?: string
    ) => {
      const copyOfState: SubGroupFilterItem[] = _.cloneDeep(targetListData);

      // const result = handleChangeMultiFiltersSelected(
      //   copyOfState,
      //   id,
      //   indexOfFiltersItem,
      //   updateType,
      //   value
      // );

      let result;
      if (name) {
        result = handleChangeFiltersSelected(
          copyOfState,
          id,
          indexOfFiltersItem,
          updateType,
          value,
          level,
          name
        );
      } else {
        result = handleChangeFiltersSelected(
          copyOfState,
          id,
          indexOfFiltersItem,
          updateType,
          value,
          level
        );
      }

      handleUpdateTargetListData(result);      
    },
    [
      handleChangeFiltersSelected,
      targetListData,
      handleUpdateTargetListData,
    ]
  );

  const handleChangeSimpleFilterSelected = useCallback(
    (
      arr: SubGroupFilterItem[],
      id: number,
      indexOfFiltersItem: number,
      value: string
    ) => {
      return arr.map((e, i) => {
        if (e.subGroupId === id) {
          const start = e.filterItemsChildren[indexOfFiltersItem].filters;
          const end = e.filterItemsChildren[indexOfFiltersItem].filtersSelected;

          if (start && end) {
            if (end.length < 1) {
              /* Add filterSelected*/
              end.push(value);
            } else {
              /* If there is one filterSelected , just replace the filter */
              end.splice(0, 1, value);
            }
          }
        } else {

          handleChangeSimpleFilterSelected(
            e.subgGroupFilterItemsChildren,
            id,
            indexOfFiltersItem,
            value
          );
        }
        return e;
      });
    },
    []
  );

  const handleUpdateSimpleFilterSelected = useCallback(
    (
      id: number,
      indexOfFiltersItem: number,
      value: string,
      isResponsive: boolean
    ) => {
      const copyOfState: SubGroupFilterItem[] = _.cloneDeep(targetListData);

      const result = handleChangeSimpleFilterSelected(
        copyOfState,
        id,
        indexOfFiltersItem,
        value
      );

      if (isResponsive) {
        fetchDependenciesOfFilters(result[0]);
      } else {
        handleUpdateTargetListData(result);
      }
    },
    [
      handleChangeSimpleFilterSelected,
      targetListData,
      handleUpdateTargetListData,
      fetchDependenciesOfFilters,
    ]
  );

  const handleUpdateSurveyNameFilterSelected = useCallback(
    (//survey test
      id: number,
      indexOfFiltersItem: number,
      updateType: "Add" | "Remove",
      value: string,
      level: number,
      name?: string
    ) => {
      const copyOfState: SubGroupFilterItem[] = _.cloneDeep(targetListData);
      let result;
      result = handleChangeFiltersSelected(
        copyOfState,
        id,
        indexOfFiltersItem,
        updateType,
        value,
        level,
        name
      );
fetchDependenciesOfFilters(result[0]);
//       if (isResponsive) {
//         console.log("handleUpdateSimpleFilterSelected : result", result[0])
//         fetchDependenciesOfFilters(result[0]);
//       } else {
//         handleUpdateTargetListData(result);
//       }
handleUpdateTargetListData(result);

    },
    [
      handleChangeFiltersSelected,
      targetListData,
      handleUpdateTargetListData,
      fetchDependenciesOfFilters
    ]
  );


  
  const handleChangeDateRangeWithSurvey = useCallback(
    (
      arr: SubGroupFilterItem[],
      id: number,
      indexOfFiltersItem: number
    ) => {
      return arr.map((e) => {
        if (e.subGroupId === id) {          
       
              const surveyNameArray: FilterItem = {
                filterId: 81,
                filters: [],
                filtersSelected: [],
                id: 81,
                isMandatory: false,
                isRecommended: false,
                isResponsive: true,
                multiSelect: false,
                name: "Survey name",
                "operatorBetweenFiltersSelected": null
              };
              const generateUniqueId = Date.now() + 200;
              const generateUniqueIdSurveyFilter =  Date.now() + 100;            

              const surveyQuestionArray: SubGroupFilterItem= {              
                id : generateUniqueId,
                subGroupId :generateUniqueId,
                name : "Survey Questions",
                operator : OperatorType.AND,
                groupType : GroupType.SURVEY_QUESTIONS,
                filterItemsChildren : [surveyNameArray],
                subgGroupFilterItemsChildren : [],
                isMCCPGroup :  false,
                parentId: generateUniqueIdSurveyFilter
              };
             const surevyFilterArray: SubGroupFilterItem =
              { 
                filterItemsChildren: [],
                groupType:  GroupType.SURVEY_FILTERS,
                id: generateUniqueIdSurveyFilter,
                isMCCPGroup: false,
                name: "Survey Filters",
                operator: OperatorType.AND,
                // operatorsBetweenGroups: null,
                parentId: e.id,
              subGroupId: generateUniqueIdSurveyFilter,
              subgGroupFilterItemsChildren: [surveyQuestionArray]
            }
           e.operator = OperatorType.AND;
           e.subgGroupFilterItemsChildren=[{...surevyFilterArray}];
        } else {
          handleChangeDateRangeWithSurvey(
            e.subgGroupFilterItemsChildren,
            id,
            indexOfFiltersItem
          );
        }

        return e;
      });
    },
    []
  );

  const handleChangeDateRange = useCallback(
    (
      arr: SubGroupFilterItem[],
      id: number,
      indexOfFiltersItem: number,
      startDate: Date,
      endDate: Date
    ) => {
      return arr.map((e) => {
        if (e.subGroupId === id) {
          e.filterItemsChildren[indexOfFiltersItem].startDateSelected =
            startDate;
          e.filterItemsChildren[indexOfFiltersItem].endDateSelected = endDate;
        } else {
          handleChangeDateRange(
            e.subgGroupFilterItemsChildren,
            id,
            indexOfFiltersItem,
            startDate,
            endDate
          );
        }

        return e;
      });
    },
    []
  );

  const handleUpdateDateRange = useCallback(
    (
      id: number,
      indexOfFiltersItem: number,
      startDate: Date,
      endDate: Date,
      isResponsive: boolean,
      groupType? : string
    ) => {
      const copyOfState: SubGroupFilterItem[] = _.cloneDeep(targetListData);
      const result = handleChangeDateRange(
        copyOfState,
        id,
        indexOfFiltersItem,
        startDate,
        endDate
      );
      if(groupType === "SURVEY"){
        const resultAfterSurvey = handleChangeDateRangeWithSurvey(
          copyOfState,
          id,
          indexOfFiltersItem
        );
      }
      fetchDependenciesOfFilters(result[0]);
      if (isResponsive) {
        fetchDependenciesOfFilters(result[0]);
      } else {
        handleUpdateTargetListData(result);
      }
    },
    [
      handleChangeDateRange,
      targetListData,
      handleUpdateTargetListData,
      fetchDependenciesOfFilters,
      handleChangeDateRangeWithSurvey
    ]
  );

  const handleGenerateTargetListPreviewText = useCallback(
    (arr: SubGroupFilterItem, level: number) => {
      const result: Array<string> = [];

      result.push(`(`);

      if(arr && arr.filterItemsChildren && arr.filterItemsChildren.length > 0 ){
      arr?.filterItemsChildren?.forEach((filter, i) => {
        if (filter && filter.filtersSelected && filter.filtersSelected.length && filter.filtersSelected.length > 1) {
          filter.filtersSelected.forEach((filterSelected, j) => {
            result.push(
              filter.name,
              " as ",
              filterSelected ?? "",
              " ",
              j < filter.filtersSelected.length - 1 ? arr.operator : " "
            );
          });
        } else {
          result.push(
            filter.filters && filter.filters.length ? filter.name : arr.name,
            filter.filters && filter.filters.length ? " as " : " from ",
            filter.filtersSelected && filter.filtersSelected[0]
              ? filter.filtersSelected[0]
              : filter.startDateSelected && filter.endDateSelected
              ? new Date(filter.startDateSelected).toLocaleDateString() +
                " - " +
                new Date(filter.endDateSelected).toLocaleDateString()
              : "",
            " ",
            i < arr.filterItemsChildren.length - 1 ? arr.operator : " "
          );
        }
      });
    }
      if (
        arr.filterItemsChildren?.length &&
        arr.subgGroupFilterItemsChildren?.length
      ) {
        result.push(arr.operator);
      }

      if (arr.subgGroupFilterItemsChildren?.length > 0) {
        arr.subgGroupFilterItemsChildren?.forEach((filter, i) => {
          result.push(
            ...handleGenerateTargetListPreviewText(filter, level + 1),
            i < arr.subgGroupFilterItemsChildren.length - 1
              ? arr.operatorsBetweenGroups?.[i] ??
                  (arr.operator ? arr.operator : "")
              : " "
          );
        });
      }
      result.push(`)`);

      return result;
    },
    []
  );

  const handleDeleteSubGroup = useCallback(
    (arr: SubGroupFilterItem[], id: number, index: number) => {
      arr.map((e) => {
        if (e.id === id) {
          e.subgGroupFilterItemsChildren.splice(index, 1);
        } else {
          handleDeleteSubGroup(e.subgGroupFilterItemsChildren, id, index);
        }
        return e;
      });
    },
    []
  );

  const handleDeleteSubGroupFilterItem = useCallback(
    (id: number | null, index: number) => {
      const copyOfState: SubGroupFilterItem[] = _.cloneDeep(targetListData);
      if (id) {
        handleDeleteSubGroup(copyOfState, id, index);
        handleUpdateTargetListData(copyOfState);
        // const res =handleDeleteGroup(copyOfState,id)
        // if(res){
        // handleUpdateTargetListData(res);}
      }
    },
    // [handleDeleteGroup,targetListData, handleUpdateTargetListData]
    [handleDeleteSubGroup, targetListData, handleUpdateTargetListData]
  );

  const onDragEnd = useCallback(
    ({ source, destination }: DropResult) => {

      // Make sure we have a valid destination
      if (destination === undefined || destination === null) return null;

      // Make sure we're actually moving the item
      if (
        source.droppableId === destination.droppableId &&
        destination.index === source.index
      )
        return null;

      // Disabled reorder for Country
      // if (
      //   parseInt(destination.droppableId) ===
      //     targetListData[0]?.subgGroupFilterItemsChildren[0]?.id &&
      //   destination.index === 0
      // )
      //   return null;

      const sourceId = source.droppableId.slice(
        source.droppableId.indexOf("-") + 1
      );

      const start = filtersDropdownData.find(
        (e) => e.id === parseInt(sourceId)
      );

      const isTargetListEmpty =
        targetListData[0].subgGroupFilterItemsChildren.length === 0;

      /* Check if targetListData is empty (only with mandatory filterItem Country), if it is, create a new group with filterItem dropped */
      if (isTargetListEmpty) {
        const filterItems =
          start &&
          start.filterItemsChildren.filter((item) => item.id === source.index);
        // const startFilterItem = start?.filterItemsChildren[source.index];
        const startFilterItem =
          filterItems && filterItems.length > 0 && filterItems[0];
        const copyOfState: SubGroupFilterItem[] = _.cloneDeep(targetListData);

        if (startFilterItem) {
          const generateUniqueId = Date.now();

          const filterItemToMove = {
            ...startFilterItem,
            filterId: generateUniqueId,
          };

          /* Cut mandatoryFilterItem to insert in a new group */
          // const mandatoryFilteritem = copyOfState[0].filterItemsChildren.splice(
          //   0,
          //   1
          // )[0];
          /* Create a new first group for target list with mandatory item*/
          const newFirstGroupCreated = generateFirstGroupOfTargetList(
            generateUniqueId,
            copyOfState[0].subGroupId
            // mandatoryFilteritem
          );

          /* Check if filterItem to add is a part of a groupFilter , if it is, create a subgroup  */
          if (filterItemToMove.groupFilter) {
            /* Create a new subgroup for the filterItem*/
            const newSubGroupFilterCreated = generateSubGroupFilter(
              filterItemToMove.groupFilter,
              generateUniqueId,
              true,
              parseInt(destination.droppableId)
            );

            /* Push new group created to copyOfState and add newSubGroupFilterCreated inside */
            copyOfState[0].subgGroupFilterItemsChildren.push({
              ...newFirstGroupCreated,
              subgGroupFilterItemsChildren: [{ ...newSubGroupFilterCreated }],
            });
          } else {
            /* Push new filterItem inside newFirstGroupCreated */
            copyOfState[0].subgGroupFilterItemsChildren.push({
              ...newFirstGroupCreated,
              filterItemsChildren: [
                ...newFirstGroupCreated.filterItemsChildren,
                filterItemToMove,
              ],
            });
          }
          /* Update targetListData with newState and dependencies load from backend */
          fetchDependenciesOfFilters(copyOfState[0]);

          /* Open new group just created */
          if (copyOfState[0]?.subgGroupFilterItemsChildren[0]?.subGroupId) {
            setGroupExpanded(
              copyOfState[0].subgGroupFilterItemsChildren[0].subGroupId
            );
          }
        }
      } else {
        /* Target List is not empty (has at least one group) */

        const copyOfState: SubGroupFilterItem[] = _.cloneDeep(targetListData);

        const endId = parseInt(destination.droppableId);

        if (source.droppableId.includes("filterList")) {
          /* Start from filterList to TargetList */

          // const startFilterItem = start?.filterItemsChildren[source.index];
          const filterItems =
            start &&
            start.filterItemsChildren.filter(
              (item) => item.id === source.index
            );
          const startFilterItem =
            filterItems && filterItems.length > 0 && filterItems[0];
          if (startFilterItem) {
            const generateUniqueId = Date.now();
            const filterItemToMove: FilterItem = {
              ...startFilterItem,
              filterId: generateUniqueId,
            };

            /* Check if filterItem should create new group when is dropped */
            if (filterItemToMove.groupFilter) {
              /* Create a new subgroup for the filterItem*/
              const newSubGroupFilterCreated = generateSubGroupFilter(
                filterItemToMove.groupFilter,
                generateUniqueId,
                false,
                endId
              );

              /* Push new group to the target list */
              handleAddFilterItemGroup(
                copyOfState,
                endId,
                newSubGroupFilterCreated,
                0
              );
            } else {
              /* Insert filterItem in target list */
              handleAddFilterItem(
                copyOfState,
                endId,
                filterItemToMove,
                destination.index
              );
            }
          }
        } else {
          if (source.droppableId === destination.droppableId) {
            //Reorder filterItem inside the TargetList

            const startItemFilter = findFilterItemById(copyOfState, endId)
              ?.filterItemsChildren[source.index];

            if (startItemFilter) {
              handleReorderFilterItem(
                copyOfState,
                endId,
                startItemFilter,
                destination.index,
                source.index
              );
            }
          } else {
            // Move filterItem in another group
            const startId = parseInt(source.droppableId);

            const startItemFilter = findFilterItemById(copyOfState, startId)
              ?.filterItemsChildren[source.index];

            // Delete filterItem from start group
            handleDeleteFilterItem(copyOfState, startId, source.index);

            // Add item to end group
            if (startItemFilter) {
              handleAddFilterItem(
                copyOfState,
                endId,
                startItemFilter,
                destination.index
              );
            }
          }
        }
        /* Update targetListData with newState and dependencies load from backend */
        fetchDependenciesOfFilters(copyOfState[0]);
      }
    },
    [
      filtersDropdownData,
      findFilterItemById,
      handleAddFilterItem,
      handleReorderFilterItem,
      targetListData,
      handleDeleteFilterItem,
      handleAddFilterItemGroup,
      fetchDependenciesOfFilters,
    ]
  );

  const handleDeleteFilterRow = useCallback(
    (id: number, index: number) => {
      const copyOfState: SubGroupFilterItem[] = _.cloneDeep(targetListData);

      handleDeleteFilterItem(copyOfState, id, index);
      handleUpdateTargetListData(copyOfState);
    },
    [handleDeleteFilterItem, targetListData, handleUpdateTargetListData]
  );

  const handleChangeOperator = useCallback(
    (arr: SubGroupFilterItem[], id: number, operator: OperatorType) => {
      return arr.map((e) => {
        if (e.subGroupId === id) {
          e.operator = operator;
        } else {
          handleChangeOperator(e.subgGroupFilterItemsChildren, id, operator);
        }

        return e;
      });
    },
    []
  );

  const handleUpdateOperator = useCallback(
    (id: number, operator: OperatorType) => {
      const copyOfState: SubGroupFilterItem[] = _.cloneDeep(targetListData);
      const result = handleChangeOperator(copyOfState, id, operator);
      handleUpdateTargetListData(result);
    },
    [handleChangeOperator, targetListData, handleUpdateTargetListData]
  );

  const handleChangeOperatorBetweenGroups = useCallback(
    (
      arr: SubGroupFilterItem[],
      id: number,
      index: number,
      operator: OperatorType
    ) => {
      return arr.map((e) => {
        if (e.subGroupId === id) {
          if (e.operatorsBetweenGroups) {
            e.operatorsBetweenGroups[index] = operator;
          } else {
            e.operatorsBetweenGroups = [operator];
          }
        } else {
          handleChangeOperatorBetweenGroups(
            e.subgGroupFilterItemsChildren,
            id,
            index,
            operator
          );
        }

        return e;
      });
    },
    []
  );

  const handleUpdateOperatorBetweenGroups = useCallback(
    (id: number, index: number, operator: OperatorType) => {
      const copyOfState: SubGroupFilterItem[] = _.cloneDeep(targetListData);
      const result = handleChangeOperatorBetweenGroups(
        copyOfState,
        id,
        index,
        operator
      );
      handleUpdateTargetListData(result);
    },
    [
      handleChangeOperatorBetweenGroups,
      targetListData,
      handleUpdateTargetListData,
    ]
  );

  const handleFindMandatoryMissing = useCallback(
    (array: SubGroupFilterItem[]): SubGroupFilterItem | undefined => {
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        if (
          element.filterItemsChildren.find(
            (f) => f.isMandatory && f.filtersSelected.length < 1
          )
        ) {
          return element;
        } else {
          if (element.subgGroupFilterItemsChildren) {
            const found = handleFindMandatoryMissing(
              element.subgGroupFilterItemsChildren
            );

            if (found) {
              console.log(found);
              return found;
            }
          }
        }
      }
    },
    []
  );

  const provider = React.useMemo<TargetV2ContextProps>(
    () => ({
      onDragEnd,
      filtersDropdownData,
      setFiltersDropdownData,
      targetListData,
      setTargetListData,
      targetListPreviewText,
      targetListPreviewSignedURL,
      targetListName,
      setTargetListName,
      targetListType,
      setTargetListType,
      varianceTL,
      setVarianceTL,
      userInteraction,
      setUserInteraction,
      setTargetListPreviewSignedURL,
      targetListCsvPreviewUrl,
      setTargetListCsvPreviewUrl,
      targetListCsvProcessingUrl,
      setTargetListCsvProcessingUrl,
      setTargetListId,
      targetListId,
      setTargetListPreviewText,
      searchingFiltersResults,
      setIsSearching,
      isSearching,
      setSearchingFiltersResults,
      handleGenerateTargetListPreviewText,
      handleDeleteFilterRow,
      handleUpdateTargetListData,
      handleUpdateFilterSelected,
      handleUpdateSurveyNameFilterSelected,
      handleChangeFiltersSelected,
      createNewMCCP,
      createSurveyFilter,
      handleDeleteSubGroup,
      handleDeleteSubGroupFilterItem,
      groupExpanded,
      setGroupExpanded,
      handleCreateNewGroup,
      handleReplicatePreviousGroup,
      handleUpdateSimpleFilterSelected,
      arrayOfOperators,
      handleUpdateOperator,
      handleUpdateOperatorBetweenGroups,
      handleUpdateDateRange,
      isApplyingFilters,
      setIsApplyingFilters,
      handleFindMandatoryMissing,
      isMissingMandatoryFields,
      setIsMissingMandatoryFields,
      fetchDependenciesOfFilters,
      fetchCountOfVolumes,
      fetchFiltersListData,
      fetchCustomTemplate,
      saveTargetonApplyFilters,
      handleUpdateMultiFilterSelected,
      handleUpdateOperatorBetweenFiltersSelected,
      countryList,
      setCountryList,
      saveTargetList,
      getFormattedURl,
      showCustomMsg,
      setShowCustomMsg
    }),
    [
      onDragEnd,
      filtersDropdownData,
      setFiltersDropdownData,
      targetListData,
      setTargetListData,
      targetListPreviewText,
      setTargetListPreviewText,
      targetListPreviewSignedURL,
      setTargetListPreviewSignedURL,
      targetListName,
      setTargetListName,
      targetListType,
      setTargetListType,
      varianceTL,
      setVarianceTL,
      userInteraction,
      setUserInteraction,
      targetListId,
      setTargetListId,
      targetListCsvPreviewUrl,
      setTargetListCsvPreviewUrl,
      targetListCsvProcessingUrl,
      setTargetListCsvProcessingUrl,
      searchingFiltersResults,
      setIsSearching,
      isSearching,
      setSearchingFiltersResults,
      handleGenerateTargetListPreviewText,
      handleDeleteFilterRow,
      handleUpdateTargetListData,
      handleUpdateFilterSelected,
      handleUpdateSurveyNameFilterSelected,
      handleChangeFiltersSelected,
      createNewMCCP,
      createSurveyFilter,
      handleDeleteSubGroup,
      handleDeleteSubGroupFilterItem,
      groupExpanded,
      setGroupExpanded,
      handleCreateNewGroup,
      handleReplicatePreviousGroup,
      handleUpdateSimpleFilterSelected,
      arrayOfOperators,
      handleUpdateOperator,
      handleUpdateOperatorBetweenGroups,
      handleUpdateDateRange,
      isApplyingFilters,
      setIsApplyingFilters,
      handleFindMandatoryMissing,
      isMissingMandatoryFields,
      setIsMissingMandatoryFields,
      fetchDependenciesOfFilters,
      fetchCountOfVolumes,
      fetchFiltersListData,
      fetchCustomTemplate,
      saveTargetonApplyFilters,
      handleUpdateMultiFilterSelected,
      handleUpdateOperatorBetweenFiltersSelected,
      countryList,
      setCountryList,
      saveTargetList,
      getFormattedURl,
      showCustomMsg,
      setShowCustomMsg
    ]
  );

  return (
    <TargetV2Context.Provider value={provider}>
      {children(provider)}
    </TargetV2Context.Provider>
  );
};

export default TargetV2Provider;

export const useTargetV2Context = () => {
  const context = useContext(TargetV2Context);

  if (context === undefined) {
    throw new Error(
      "TargetV2Context must be used within a TargetV2ContextProvider"
    );
  }

  return context;
};
